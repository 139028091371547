<template>
  <div class="product_repo table_container">
    <div class="table_title">
      <div v-if="props.isContrast" class="title_div chinese">产品图片</div>
      <div v-else class="title_div chinese">
        {{ props.productName }} - 产品图片
      </div>
    </div>
    <el-tabs
      style="padding-left: 25px"
      v-if="props.isContrast"
      v-model="activeName"
      class="demo-tabs"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="item in tabsValue"
        :key="item.name"
        :label="item.label"
        :name="item.name"
      />
    </el-tabs>
    <div class="image_box">
      <div class="lantern_slide">
        <div class="arrow_box">
          <div @click="prevClick()" class="round">
            <svg-icon class-name="dashboard_arrow-left" class="icon">
            </svg-icon>
          </div>
          <div @click="nextClick()" class="round">
            <svg-icon class-name="dashboard_arrow-right" class="icon">
            </svg-icon>
          </div>
        </div>
        <div class="active_image">
          <el-image
            v-if="activeData.link"
            :src="activeData.link"
            placeholder
          ></el-image>
        </div>
      </div>

      <div class="image_list">
        <div
          class="item_image_div"
          @click="switchover(index, item)"
          v-for="(item, index) in renderData"
          :key="item.id"
        >
          <div :class="['item_image', { image_border: item.active }]">
            <img v-lazy="item.link" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { repoImgs } from "@/api/productRepo";
import { ElMessage } from "element-plus";

// const baseURL = process.env.VUE_APP_API_HOST;
const activeData = ref({});
const renderData = ref([]);
const activeIndex = ref(0);
const tabsValue = ref([]);
const activeName = ref("");
const props = defineProps({
  productId: {
    type: Array,
    default() {
      return [];
    },
  },
  productName: {
    type: String,
    default: "",
  },
  isContrast: {
    type: String,
    default: null,
  },
});

//页面初始化
const initData = () => {
  const productIdList = props.productId.split(",");
  const productNameList = props.productName.split(",");
  getProductImg({ productId: productIdList[0] });

  //设置tabs值的格式
  productIdList.forEach((item, index) => {
    tabsValue.value.push({
      name: item,
      label: productNameList[index],
    });
  });
  activeName.value = tabsValue.value[0].name;
};

//获取第一个productId请求数据
const getProductImg = async (params) => {
  renderData.value = [];
  const { data } = await repoImgs(params);
  if (!data.length) {
    renderData.value = data;
    activeData.value = [];
    return ElMessage({
      message: "该产品暂无产品图片",
      type: "warning",
    });
  }
  renderData.value = data.map((item) => {
    return {
      link: item,
      active: false,
    };
  });
  renderData.value[0].active = true;
  const first = renderData.value[0];
  activeIndex.value = 0;
  activeData.value = first;
};

const prevClick = () => {
  const length = renderData.value.length - 1;
  activeIndex.value = activeIndex.value ? activeIndex.value - 1 : length;
  activeData.value = renderData.value[activeIndex.value];
  codeBlock(activeIndex.value);
};

const nextClick = () => {
  activeIndex.value =
    activeIndex.value === renderData.value.length - 1
      ? 0
      : activeIndex.value + 1;
  activeData.value = renderData.value[activeIndex.value];
  codeBlock(activeIndex.value);
};
const switchover = (index, item) => {
  activeIndex.value = index;
  activeData.value = item;
  codeBlock(index);
};

const codeBlock = (index) => {
  renderData.value.forEach((item) => {
    item.active = false;
  });
  renderData.value[index].active = true;
};

const handleClick = (tab) => {
  const currentId = tab.props.name;
  getProductImg({ productId: currentId });
};

initData();
</script>

<style scoped lang="scss">
.table_title {
  margin: 6px 0 16px 0;
  font-size: 16px;
  color: #303133;

  .title_div {
    font-weight: 700;
  }

  .chinese {
    margin-bottom: 4px;
  }
}

.image_box {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  box-sizing: border-box;

  .lantern_slide {
    position: relative;
    width: 560px;
    height: 733px;

    .arrow_box {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 100%;
      height: 30px;
      padding: 0 17px;
      display: flex;
      justify-content: space-between;
      z-index: 9;

      .round {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #9ca6b3;
        cursor: pointer;

        .icon {
          color: #f0f2f5;
        }
      }
    }

    .active_image {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      position: relative;
      padding: 0 60px;
      background-color: #c7d3e4;
      border: 1px solid #ccc;

      .el-image {
        width: 100%;
        background-color: #fff;
        height: auto;

        .el-image__inner {
          height: auto !important;
        }
      }
    }
  }

  .image_list {
    width: 505px;
    height: 733px;
    overflow-x: auto;
    padding: 0 23px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    box-sizing: border-box;

    .item_image_div:nth-child(odd) {
      margin-right: 36px;
    }

    .item_image {
      margin-bottom: 36px;
      width: 200px;
      height: 220px;
      overflow: hidden;
      border: 2px solid #f0f2f5;

      div {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image_border {
      border: 2px solid #409eff;
    }
  }
}
</style>
<style lang="scss">
.el-dialog.productRepo_showImage {
  .el-dialog__header {
    padding: 0 !important;
  }

  .active_image {
    .el-image {
      .el-image__inner {
        height: auto !important;
      }
    }
  }
}
</style>
