<template>
  <div class="product_repo">
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <div class="table_box">
        <div class="explain_box">
          <el-button size="small" @click="showProductImage" type="primary"
            >显示产品图片</el-button
          >
          <div class="explain">
            <svg-icon
              class-name="tendency_question"
              class="icon tendency_question"
            >
            </svg-icon>
            <div class="outstanding">
              <div class="allege text_allege">
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
              <span>产品具有该文字宣称</span>
            </div>
            <div class="outstanding">
              <div class="allege number_allege">
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
              <span>产品具有该数字宣称</span>
            </div>
            <div class="outstanding">
              <div class="allege text_allege_outstanding"></div>
              <span>产品所属品类文字宣称指标显著</span>
            </div>
            <div class="outstanding">
              <div class="allege number_allege_outstanding"></div>
              <span>产品所属品类数字宣称指标显著</span>
            </div>
          </div>
        </div>
        <el-table
          v-if="!routerQuery.isContrast"
          v-loading="loading"
          :data="tableData"
          :span-method="arraySpanMethod"
          :cell-style="cellStyle"
          border
          height="1000px"
        >
          <el-table-column
            prop="claimLevel1"
            label="一级宣称"
            width="223"
            align="center"
          />
          <el-table-column
            prop="claimLevel2"
            label="二级宣称"
            width="224"
            align="center"
          />
          <el-table-column
            prop="textClaim"
            label="文字宣称"
            width="85"
            align="center"
          >
            <template #default="{ row }">
              <div
                v-if="row.isHaveTextClaimLevel2 === '1'"
                class="allege text_allege"
              >
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="numberClaim"
            label="数字宣称"
            width="85"
            align="center"
          >
            <template #default="{ row }">
              <div
                v-if="row.isHaveNumericClaimLevel2 === '1'"
                class="allege number_allege"
              >
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="textClaimLevel3"
            label="三级文字宣称"
            align="center"
            width="224"
          />
          <el-table-column
            prop="textClaim1"
            label="文字宣称"
            width="85"
            align="center"
          >
            <template #default="{ row }">
              <div
                v-if="row.isHaveTextClaimLevel3 === '1'"
                class="allege text_allege"
              >
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="numericClaimLevel3"
            label="三级数字宣称"
            align="center"
            width="224"
          />
          <el-table-column
            prop="numberClaim1"
            label="数字宣称"
            width="85"
            align="center"
          >
            <template #default="{ row }">
              <div
                v-if="row.isHaveNumericClaimLevel3 === '1'"
                class="allege number_allege"
              >
                <svg-icon class-name="comparison" class="icon"> </svg-icon>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-table
          v-else
          :data="tableData"
          v-loading="loading"
          :header-cell-style="headerCellStyle"
          :cell-style="cellStyle"
          :span-method="arraySpanMethod"
          height="1000"
        >
          <el-table-column
            v-for="item in tableHeader"
            :key="item"
            :label="item.label"
            align="center"
            :width="item.width"
          >
            <template #header>
              {{ item.label }}
            </template>
            <template #default v-if="item.iteration">
              <el-table-column
                v-for="(childItem, index) in item.iteration"
                :key="childItem"
                :label="childItem.label"
                align="center"
              >
                <template #header="{ column }">
                  {{ column.label }}
                </template>

                <template #default="{ row }">
                  <template v-if="item.iteration.length > 1">
                    <span
                      v-if="
                        row[childItem.prop].indexNumericClaimLevel &&
                        row[childItem.prop].indexTextClaimLevel &&
                        index % 2 !== 0
                      "
                    >
                      <div
                        v-if="
                          row[childItem.prop].isHaveNumericClaimLevel === '1'
                        "
                        class="allege number_allege"
                      >
                        <svg-icon class-name="comparison" class="icon">
                        </svg-icon>
                      </div>
                    </span>
                    <span
                      v-else-if="
                        row[childItem.prop].indexNumericClaimLevel &&
                        row[childItem.prop].indexTextClaimLevel &&
                        index % 2 === 0
                      "
                    >
                      <div
                        v-if="row[childItem.prop].isHaveTextClaimLevel === '1'"
                        class="allege text_allege"
                      >
                        <svg-icon class-name="comparison" class="icon">
                        </svg-icon>
                      </div>
                    </span>
                  </template>
                  <template v-else>
                    <span v-if="row[childItem.prop].indexNumericClaimLevel">
                      <div
                        v-if="
                          row[childItem.prop].isHaveNumericClaimLevel === '1'
                        "
                        class="allege number_allege"
                      >
                        <svg-icon class-name="comparison" class="icon">
                        </svg-icon>
                      </div>
                    </span>
                    <span v-if="row[childItem.prop].indexTextClaimLevel">
                      <div
                        v-if="row[childItem.prop].isHaveTextClaimLevel === '1'"
                        class="allege text_allege"
                      >
                        <svg-icon class-name="comparison" class="icon">
                        </svg-icon>
                      </div>
                    </span>
                  </template>
                </template>
              </el-table-column>
            </template>
            <template #default="{ row }" v-else>
              <span> {{ row[item.prop] }} </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
  <el-dialog
    v-model="showProduct"
    width="1157px"
    top="10vh"
    destroy-on-close
    custom-class="productRepo_showImage"
  >
    <showProductImgVue
      :productId="routerQuery.productIds"
      :productName="routerQuery.productName"
      :isContrast="routerQuery.isContrast"
    ></showProductImgVue>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { claimDetail } from "@/api/productRepo";
import showProductImgVue from "./showProductImg.vue";

const router = useRouter();
const routerQuery = ref({});
const tableHeader = ref([]);
const tableData = ref([]);
const loading = ref(false);
const productNum = ref([]);
const showProduct = ref(false);
const setColumn = reactive({
  textClaimLevel3: 0,
  numericClaimLevel3: 0,
  textClaim: {
    level2: [],
    level3: [],
  },
  numericClaim: {
    level2: [],
    level3: [],
  },
});

const initialize = () => {
  routerQuery.value = router.currentRoute.value.query;
  productNum.value = routerQuery.value.productIds.split(",");
  if (!routerQuery.value.isContrast) setCrumbs();
  getClaimDetail();
};

//设置单产品面包屑文字内容
const setCrumbs = () => {
  const element = document.querySelectorAll(".el-breadcrumb__inner")[2];
  element.innerText = routerQuery.value.productName;
};

//获取表格数据
const getClaimDetail = async () => {
  const params = {
    category: routerQuery.value.category,
    timeRange: routerQuery.value.timeRange,
    productIds: routerQuery.value.productIds,
  };

  loading.value = true;
  const { data } = await claimDetail(params);

  if (routerQuery.value.isContrast) {
    tableHeader.value = data.tableHeader.map((item) => {
      if (item.prop === "claimLevel1") {
        item.width = "170";
      } else if (item.prop === "claimLevel2") {
        item.width = "170";
      } else if (item.prop === "textClaimLevel3") {
        item.width = "170";
      } else if (item.prop === "numericClaimLevel3") {
        item.width = "160";
      } else {
        item.width = null;
      }
      return item;
    });
    tableData.value = data.tableBody.map((item) => {
      item.textClaimLevel3 =
        item.textClaimLevel3 === ""
          ? "na"
          : item.textClaimLevel3.replace("||", "\n");
      item.numericClaimLevel3 =
        item.numericClaimLevel3 === ""
          ? "na"
          : item.numericClaimLevel3.replace("||", "\n");
      return item;
    });
  } else {
    tableData.value = data.map((item) => {
      item.textClaimLevel3 =
        item.textClaimLevel3 === ""
          ? "na"
          : item.textClaimLevel3.replace("||", "\n");
      item.numericClaimLevel3 =
        item.numericClaimLevel3 === ""
          ? "na"
          : item.numericClaimLevel3.replace("||", "\n");
      return item;
    });
  }
  loading.value = false;
  await setColumnIndex(productNum.value.length);
  await setClaimColumnIndex(productNum.value.length);
};

//找到三级文字数字宣称在列中的位置
const setColumnIndex = (length) => {
  tableHeader.value.forEach((item, index) => {
    if (item.prop === "textClaimLevel3") {
      setColumn.textClaimLevel3 = index + length;
    }
    if (item.prop === "numericClaimLevel3") {
      setColumn.numericClaimLevel3 = index + length;
    }
  });
};

//找到每一个产品文字数字宣称在列中的位置
const setClaimColumnIndex = (length) => {
  const textClaimLevel2 = 1 + length * 2;
  for (let i = 2; i < textClaimLevel2 + 1; i++) {
    if (i % 2 === 0) {
      setColumn.textClaim.level2.push(i);
    } else {
      setColumn.numericClaim.level2.push(i);
    }
  }

  for (
    let i = setColumn.textClaimLevel3 + 1;
    i < setColumn.textClaimLevel3 + length + 1;
    i++
  ) {
    setColumn.textClaim.level3.push(i);
  }

  for (
    let i = setColumn.numericClaimLevel3 + 1;
    i < setColumn.numericClaimLevel3 + length + 1;
    i++
  ) {
    setColumn.numericClaim.level3.push(i);
  }
};

initialize();

const arraySpanMethod = ({ row, rowIndex, columnIndex }) => {
  row.claimLevel1 = row.claimLevel1.replace("||", "\n");
  row.claimLevel2 = row.claimLevel2.replace("||", "\n");
  if (columnIndex === 0) {
    const sameRoutIdList = tableData.value.filter(
      (i) => i.claimLevel1 === row.claimLevel1
    );
    const firstIndex = tableData.value.findIndex(
      (i) => i.claimLevel1 === row.claimLevel1
    );
    return mergeCell(sameRoutIdList, firstIndex, rowIndex);
  }
  const textClaim = setColumn.textClaim.level2;
  const numericClaim = setColumn.numericClaim.level2;
  if (
    columnIndex === 1 ||
    textClaim.includes(columnIndex) ||
    numericClaim.includes(columnIndex)
  ) {
    //过滤出相同的数据
    const sameRoutIdList = tableData.value.filter(
      (i) => i.claimLevel2 === row.claimLevel2
    );
    //找到相同数据的第一个下标
    const firstIndex = tableData.value.findIndex(
      (i) => i.claimLevel2 === row.claimLevel2
    );
    return mergeCell(sameRoutIdList, firstIndex, rowIndex);
  }

  if (routerQuery.value.isContrast) {
    if (columnIndex === setColumn.textClaimLevel3) {
      const sameRoutIdList = tableData.value.filter((i) => {
        return (
          i.claimLevel2 === row.claimLevel2 &&
          i.textClaimLevel3 === "na" &&
          row.textClaimLevel3 === "na"
        );
      });
      const firstIndex = tableData.value.findIndex((i) => {
        return (
          i.claimLevel2 === row.claimLevel2 &&
          i.textClaimLevel3 === "na" &&
          row.textClaimLevel3 === "na"
        );
      });
      return mergeCell(sameRoutIdList, firstIndex, rowIndex);
    }

    if (columnIndex === setColumn.numericClaimLevel3) {
      const sameRoutIdList = tableData.value.filter((i) => {
        return (
          i.claimLevel2 === row.claimLevel2 &&
          i.numericClaimLevel3 === "na" &&
          row.numericClaimLevel3 === "na"
        );
      });
      const firstIndex = tableData.value.findIndex((i) => {
        return (
          i.claimLevel2 === row.claimLevel2 &&
          i.numericClaimLevel3 === "na" &&
          row.numericClaimLevel3 === "na"
        );
      });
      return mergeCell(sameRoutIdList, firstIndex, rowIndex);
    }
  } else {
    if (columnIndex === 4) {
      const sameRoutIdList = tableData.value.filter((i) => {
        return (
          i.claimLevel2 === row.claimLevel2 &&
          i.textClaimLevel3 === "na" &&
          row.textClaimLevel3 === "na"
        );
      });
      const firstIndex = tableData.value.findIndex((i) => {
        return (
          i.claimLevel2 === row.claimLevel2 &&
          i.textClaimLevel3 === "na" &&
          row.textClaimLevel3 === "na"
        );
      });
      return mergeCell(sameRoutIdList, firstIndex, rowIndex);
    }
    if (columnIndex === 6) {
      const sameRoutIdList = tableData.value.filter((i) => {
        return (
          i.claimLevel2 === row.claimLevel2 &&
          i.numericClaimLevel3 === "na" &&
          row.numericClaimLevel3 === "na"
        );
      });
      const firstIndex = tableData.value.findIndex((i) => {
        return (
          i.claimLevel2 === row.claimLevel2 &&
          i.numericClaimLevel3 === "na" &&
          row.numericClaimLevel3 === "na"
        );
      });
      return mergeCell(sameRoutIdList, firstIndex, rowIndex);
    }
  }
};

const cellStyle = ({ row, columnIndex }) => {
  if (columnIndex === 1) {
    return "background-color:#F6F8FA";
  }
  if (routerQuery.value.isContrast) {
    if (
      columnIndex === setColumn.textClaimLevel3 &&
      row.textClaimLevel3 === "na"
    ) {
      return "background-color:#FFF;color:rgb(192, 195, 203)";
      // return "background-color:#C0C4CC";
    }
    if (
      columnIndex === setColumn.numericClaimLevel3 &&
      row.numericClaimLevel3 === "na"
    ) {
      return "background-color:#FFF;color:rgb(192, 195, 203)";
    }
    if (
      columnIndex === setColumn.textClaimLevel3 ||
      columnIndex === setColumn.numericClaimLevel3
    ) {
      return "background-color:#F6F8FA";
    }

    for (let i = 0; i < setColumn.textClaim.level2.length; i++) {
      if (columnIndex === setColumn.textClaim.level2[i]) {
        const { indexTextClaimLevel } = row[`claimLevel2Product_${i}`] || {};
        if (indexTextClaimLevel > "1.05") {
          return "background-color:rgba(50, 163, 190, .25)";
        }
      }
    }

    for (let i = 0; i < setColumn.textClaim.level3.length; i++) {
      if (columnIndex === setColumn.textClaim.level3[i]) {
        const { indexTextClaimLevel } =
          row[`textClaimLevel3Product_${i}`] || {};
        if (indexTextClaimLevel > "1.05") {
          return "background-color:rgba(50, 163, 190, .25)";
        }
      }
    }

    for (let i = 0; i < setColumn.numericClaim.level2.length; i++) {
      if (columnIndex === setColumn.numericClaim.level2[i]) {
        const { indexNumericClaimLevel } = row[`claimLevel2Product_${i}`] || {};
        if (indexNumericClaimLevel > "1.05") {
          return "background-color:rgba(227, 120, 147,.25)";
        }
      }
    }

    for (let i = 0; i < setColumn.numericClaim.level3.length; i++) {
      if (columnIndex === setColumn.numericClaim.level3[i]) {
        const { indexNumericClaimLevel } =
          row[`numericClaimLevel3Product_${i}`] || {};
        if (indexNumericClaimLevel > "1.05") {
          return "background-color:rgba(227, 120, 147,.25)";
        }
      }
    }
  } else {
    if (
      (columnIndex === 4 && row.textClaimLevel3 === "na") ||
      (columnIndex === 6 && row.numericClaimLevel3 === "na")
    ) {
      return "background-color:#FFF;color:rgb(192, 195, 203)";
    }
    if (columnIndex === 2 && row.indexTextClaimLevel2 > "1.05") {
      return "background-color:rgba(50, 163, 190, .25)";
    }
    if (columnIndex === 3 && row.indexNumericClaimLevel2 > "1.05") {
      return "background-color:rgba(227, 120, 147,.25)";
    }
    if (columnIndex === 4 && row.textClaimLevel3 === "") {
      return "background-color:#C0C4CC";
    }
    if (columnIndex === 5 && row.indexTextClaimLevel3 > "1.05") {
      return "background-color:rgba(50, 163, 190, .25)";
    }
    if (columnIndex === 6 && row.numericClaimLevel3 === "") {
      return "background-color:#C0C4CC";
    }
    if (columnIndex === 7 && row.indexNumericClaimLevel3 > "1.05") {
      return "background-color:rgba(227, 120, 147,.25)";
    }
  }
};

const showProductImage = async () => {
  showProduct.value = true;
};

//合并单元格代码复用
const mergeCell = (sameRoutIdList, firstIndex, rowIndex) => {
  if (sameRoutIdList.length > 1) {
    if (rowIndex === firstIndex) {
      return {
        rowspan: sameRoutIdList.length,
        colspan: 1,
      };
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
  } else {
    return {
      rowspan: 1,
      colspan: 1,
    };
  }
};

router.afterEach((to, form) => {
  if (to.name !== "ProductRepo" && form.name == "ProductClaim") {
    localStorage.removeItem("PRODUCTREPO");
  }
});
</script>

<style lang="scss" scoped>
.product_repo {
  .table_box {
    color: #777777;
    padding: 0 58px;
    .explain_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .explain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .tendency_question {
          font-size: 22px;
        }
      }
    }
    .allege {
      margin: 0 auto;
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      // background-color: #32a3be;
      color: #32a3be;
      font-size: 20px;
    }
    .allege.number_allege {
      // background-color: #f56c6c;
      color: #f56c6c;
    }
    .outstanding {
      margin-left: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;

      .text_allege_outstanding {
        opacity: 0.5;
        background-color: #32a3be;
      }
      .number_allege_outstanding {
        opacity: 0.5;
        background-color: #e37893;
      }
      span {
        margin-left: 8px;
      }
    }
  }
  .el-button--primary {
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
  }
}
</style>
<style lang="scss">
.product_repo {
  .el-table th.el-table__cell > .cell {
    font-weight: 700;
  }
  .cell {
    padding: 0 4px;
    white-space: pre-line !important;
  }
  .el-table thead.is-group th.el-table__cell {
    background-color: #fff;
  }
  .el-table--border .el-table__cell:first-child .cell {
    padding: 0;
  }
  .el-table .el-table__cell {
    padding: 4px 0;
  }
}
</style>
